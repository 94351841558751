const allowedUnauthorizedPathNames = ['login', 'forgot-password', 'sign-up']

export default defineNuxtRouteMiddleware((to) => {
  const accessToken = useStatefulCookie<string>('accessToken')

  if (!accessToken.value && !allowedUnauthorizedPathNames.includes(to.name)) {
    return navigateTo({ name: 'login' })
  }
  return true
})
