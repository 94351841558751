import { defineNuxtPlugin } from '#imports'
      import { plugin, defaultConfig, ssrComplete } from '@formkit/vue'
      import { resetCount } from '@formkit/core'

      import importedConfig from '/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/formkit.config.ts'

      export default defineNuxtPlugin((nuxtApp) => {
        const config = defaultConfig(typeof importedConfig === 'function' ? importedConfig() : importedConfig)
        nuxtApp.hook('app:rendered', (renderContext) => {
          resetCount()
          ssrComplete(nuxtApp.vueApp)
        })
        nuxtApp.vueApp.use(plugin, config)

      })
      