import revive_payload_client_ZBymxKQYe3 from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@unocss+reset@0.61.0_eslint@8.57.0_floating-vue@5.2.2_ro_bng4pprvrhqp44io5i6evmklt4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5AfWixgK5f from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@unocss+reset@0.61.0_eslint@8.57.0_floating-vue@5.2.2_ro_bng4pprvrhqp44io5i6evmklt4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aMMT0nFZvO from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@unocss+reset@0.61.0_eslint@8.57.0_floating-vue@5.2.2_ro_bng4pprvrhqp44io5i6evmklt4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_359seYh43R from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@unocss+reset@0.61.0_eslint@8.57.0_floating-vue@5.2.2_ro_bng4pprvrhqp44io5i6evmklt4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_czRzD89abl from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@unocss+reset@0.61.0_eslint@8.57.0_floating-vue@5.2.2_ro_bng4pprvrhqp44io5i6evmklt4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_hUtmV96iV1 from "/usr/src/node-app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/node-app/.nuxt/components.plugin.mjs";
import prefetch_client_m18CAK8Fuz from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@unocss+reset@0.61.0_eslint@8.57.0_floating-vue@5.2.2_ro_bng4pprvrhqp44io5i6evmklt4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/usr/src/node-app/.nuxt/formkitPlugin.mjs";
import i18n_YvA15XKCav from "/usr/src/node-app/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.18.0_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_l5jMlg3h8i from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/sentry/runtime/plugin.client.ts";
import session_client_vBwyMXmtmV from "/usr/src/node-app/node_modules/.pnpm/nuxt-auth-utils@0.1.0_rollup@4.18.0/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import plugin_rkrEJ1oy1V from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/store/runtime/plugin.ts";
import plugin_HdFBAu59CN from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/04.error/runtime/plugin.ts";
import conversion_plugin_MSBIuAB2gS from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/runtime/conversion-plugin.ts";
import login_plugin_SVBwzjHVse from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/01.authentication/runtime/login-plugin.ts";
import chunk_reload_client_I6Hh9A7VRa from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@unocss+reset@0.61.0_eslint@8.57.0_floating-vue@5.2.2_ro_bng4pprvrhqp44io5i6evmklt4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_z05CxQe16R from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/02.integrations/runtime/plugin.ts";
import umami_UbpOuIUCD6 from "/usr/src/node-app/node_modules/.pnpm/nuxt-umami@2.6.2/node_modules/nuxt-umami/plugins/umami.ts";
import _3_user_4ciH5LEklA from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/plugins/3.user.ts";
import _5_crisp_client_Txq1ukvZ2D from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/plugins/5.crisp.client.ts";
export default [
  revive_payload_client_ZBymxKQYe3,
  unhead_5AfWixgK5f,
  router_aMMT0nFZvO,
  payload_client_359seYh43R,
  check_outdated_build_client_czRzD89abl,
  plugin_vue3_hUtmV96iV1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_m18CAK8Fuz,
  formkitPlugin_pZqjah0RUG,
  i18n_YvA15XKCav,
  plugin_client_l5jMlg3h8i,
  session_client_vBwyMXmtmV,
  plugin_rkrEJ1oy1V,
  plugin_HdFBAu59CN,
  conversion_plugin_MSBIuAB2gS,
  login_plugin_SVBwzjHVse,
  chunk_reload_client_I6Hh9A7VRa,
  plugin_z05CxQe16R,
  umami_UbpOuIUCD6,
  _3_user_4ciH5LEklA,
  _5_crisp_client_Txq1ukvZ2D
]