import { default as deletedeAQCNQo1J6Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/deleted.vue?macro=true";
import { default as privacyLHX0uulRzNMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/en/privacy.vue?macro=true";
import { default as tosAf1j5CvF5KMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/en/tos.vue?macro=true";
import { default as forgot_45passwordiEX2CslqCZMeta } from "/usr/src/node-app/pages/forgot-password.vue?macro=true";
import { default as imprintNsgdS5DSGJMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/imprint.vue?macro=true";
import { default as indexXJM6PR2PKcMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/index.vue?macro=true";
import { default as loging8ylET9X4cMeta } from "/usr/src/node-app/pages/login.vue?macro=true";
import { default as privacyeKvnTXG5G8Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/privacy.vue?macro=true";
import { default as settingsUiNLaa2bTkMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/settings.vue?macro=true";
import { default as sign_45upSs8IePDU5BMeta } from "/usr/src/node-app/pages/sign-up.vue?macro=true";
import { default as smatching_45demoHVuIgOOW3vMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/smatching-demo.vue?macro=true";
import { default as tosVpgTMeHTZBMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/tos.vue?macro=true";
import { default as indexcDKopFJ4wEMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/store/index.vue?macro=true";
import { default as index8HEbz5WaQGMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/store/checkout/index.vue?macro=true";
import { default as _91id_93kdDrfbN2JpMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/store/voucher/[id].vue?macro=true";
import { default as templatepyhqI2QnMcMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/store/template.vue?macro=true";
import { default as _91id_93pXRKxjlk1LMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/store/checkout/completed/[id].vue?macro=true";
import { default as indexJ2SpIGIXBKMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/index.vue?macro=true";
import { default as invite6BiP1hFafgMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/invite.vue?macro=true";
import { default as smatchingkodpVFnrn6Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/smatching.vue?macro=true";
import { default as helpIJzQOdfQx6Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/help.vue?macro=true";
import { default as indexo6gvjD7hBVMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/lottery/index.vue?macro=true";
import { default as ticketsMjyt5c9uv1Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/lottery/tickets.vue?macro=true";
export default [
  {
    name: deletedeAQCNQo1J6Meta?.name ?? "deleted",
    path: deletedeAQCNQo1J6Meta?.path ?? "/deleted",
    meta: deletedeAQCNQo1J6Meta || {},
    alias: deletedeAQCNQo1J6Meta?.alias || [],
    redirect: deletedeAQCNQo1J6Meta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/deleted.vue").then(m => m.default || m)
  },
  {
    name: privacyLHX0uulRzNMeta?.name ?? "en-privacy",
    path: privacyLHX0uulRzNMeta?.path ?? "/en/privacy",
    meta: privacyLHX0uulRzNMeta || {},
    alias: privacyLHX0uulRzNMeta?.alias || [],
    redirect: privacyLHX0uulRzNMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/en/privacy.vue").then(m => m.default || m)
  },
  {
    name: tosAf1j5CvF5KMeta?.name ?? "en-tos",
    path: tosAf1j5CvF5KMeta?.path ?? "/en/tos",
    meta: tosAf1j5CvF5KMeta || {},
    alias: tosAf1j5CvF5KMeta?.alias || [],
    redirect: tosAf1j5CvF5KMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/en/tos.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordiEX2CslqCZMeta?.name ?? "forgot-password",
    path: forgot_45passwordiEX2CslqCZMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordiEX2CslqCZMeta || {},
    alias: forgot_45passwordiEX2CslqCZMeta?.alias || [],
    redirect: forgot_45passwordiEX2CslqCZMeta?.redirect,
    component: () => import("/usr/src/node-app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: imprintNsgdS5DSGJMeta?.name ?? "imprint",
    path: imprintNsgdS5DSGJMeta?.path ?? "/imprint",
    meta: imprintNsgdS5DSGJMeta || {},
    alias: imprintNsgdS5DSGJMeta?.alias || [],
    redirect: imprintNsgdS5DSGJMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: indexXJM6PR2PKcMeta?.name ?? "index",
    path: indexXJM6PR2PKcMeta?.path ?? "/",
    meta: indexXJM6PR2PKcMeta || {},
    alias: indexXJM6PR2PKcMeta?.alias || [],
    redirect: indexXJM6PR2PKcMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loging8ylET9X4cMeta?.name ?? "login",
    path: loging8ylET9X4cMeta?.path ?? "/login",
    meta: loging8ylET9X4cMeta || {},
    alias: loging8ylET9X4cMeta?.alias || [],
    redirect: loging8ylET9X4cMeta?.redirect,
    component: () => import("/usr/src/node-app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: privacyeKvnTXG5G8Meta?.name ?? "privacy",
    path: privacyeKvnTXG5G8Meta?.path ?? "/privacy",
    meta: privacyeKvnTXG5G8Meta || {},
    alias: privacyeKvnTXG5G8Meta?.alias || [],
    redirect: privacyeKvnTXG5G8Meta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: settingsUiNLaa2bTkMeta?.name ?? "settings",
    path: settingsUiNLaa2bTkMeta?.path ?? "/settings",
    meta: settingsUiNLaa2bTkMeta || {},
    alias: settingsUiNLaa2bTkMeta?.alias || [],
    redirect: settingsUiNLaa2bTkMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: sign_45upSs8IePDU5BMeta?.name ?? "sign-up",
    path: sign_45upSs8IePDU5BMeta?.path ?? "/sign-up",
    meta: sign_45upSs8IePDU5BMeta || {},
    alias: sign_45upSs8IePDU5BMeta?.alias || [],
    redirect: sign_45upSs8IePDU5BMeta?.redirect,
    component: () => import("/usr/src/node-app/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: smatching_45demoHVuIgOOW3vMeta?.name ?? "smatching-demo",
    path: smatching_45demoHVuIgOOW3vMeta?.path ?? "/smatching-demo",
    meta: smatching_45demoHVuIgOOW3vMeta || {},
    alias: smatching_45demoHVuIgOOW3vMeta?.alias || [],
    redirect: smatching_45demoHVuIgOOW3vMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/smatching-demo.vue").then(m => m.default || m)
  },
  {
    name: tosVpgTMeHTZBMeta?.name ?? "tos",
    path: tosVpgTMeHTZBMeta?.path ?? "/tos",
    meta: tosVpgTMeHTZBMeta || {},
    alias: tosVpgTMeHTZBMeta?.alias || [],
    redirect: tosVpgTMeHTZBMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/pages/tos.vue").then(m => m.default || m)
  },
  {
    name: templatepyhqI2QnMcMeta?.name ?? undefined,
    path: templatepyhqI2QnMcMeta?.path ?? "/store",
    meta: templatepyhqI2QnMcMeta || {},
    alias: templatepyhqI2QnMcMeta?.alias || [],
    redirect: templatepyhqI2QnMcMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/store/template.vue").then(m => m.default || m),
    children: [
  {
    name: indexcDKopFJ4wEMeta?.name ?? "store-index",
    path: indexcDKopFJ4wEMeta?.path ?? "",
    meta: indexcDKopFJ4wEMeta || {},
    alias: indexcDKopFJ4wEMeta?.alias || [],
    redirect: indexcDKopFJ4wEMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/store/index.vue").then(m => m.default || m)
  },
  {
    name: index8HEbz5WaQGMeta?.name ?? "store-checkout",
    path: index8HEbz5WaQGMeta?.path ?? "/store/checkout",
    meta: index8HEbz5WaQGMeta || {},
    alias: index8HEbz5WaQGMeta?.alias || [],
    redirect: index8HEbz5WaQGMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/store/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kdDrfbN2JpMeta?.name ?? "store-voucher-id",
    path: _91id_93kdDrfbN2JpMeta?.path ?? "/store/voucher/:id",
    meta: _91id_93kdDrfbN2JpMeta || {},
    alias: _91id_93kdDrfbN2JpMeta?.alias || [],
    redirect: _91id_93kdDrfbN2JpMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/store/voucher/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93pXRKxjlk1LMeta?.name ?? "store-checkout-completed-id",
    path: _91id_93pXRKxjlk1LMeta?.path ?? "/store/checkout/completed/:id",
    meta: _91id_93pXRKxjlk1LMeta || {},
    alias: _91id_93pXRKxjlk1LMeta?.alias || [],
    redirect: _91id_93pXRKxjlk1LMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/store/checkout/completed/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJ2SpIGIXBKMeta?.name ?? "profile",
    path: indexJ2SpIGIXBKMeta?.path ?? "/profile",
    meta: indexJ2SpIGIXBKMeta || {},
    alias: indexJ2SpIGIXBKMeta?.alias || [],
    redirect: indexJ2SpIGIXBKMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: invite6BiP1hFafgMeta?.name ?? "profile-invite",
    path: invite6BiP1hFafgMeta?.path ?? "/profile/invite",
    meta: invite6BiP1hFafgMeta || {},
    alias: invite6BiP1hFafgMeta?.alias || [],
    redirect: invite6BiP1hFafgMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/invite.vue").then(m => m.default || m)
  },
  {
    name: smatchingkodpVFnrn6Meta?.name ?? "smatching",
    path: smatchingkodpVFnrn6Meta?.path ?? "/smatching",
    meta: smatchingkodpVFnrn6Meta || {},
    alias: smatchingkodpVFnrn6Meta?.alias || [],
    redirect: smatchingkodpVFnrn6Meta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/smatching.vue").then(m => m.default || m)
  },
  {
    name: helpIJzQOdfQx6Meta?.name ?? "help",
    path: helpIJzQOdfQx6Meta?.path ?? "/help",
    meta: helpIJzQOdfQx6Meta || {},
    alias: helpIJzQOdfQx6Meta?.alias || [],
    redirect: helpIJzQOdfQx6Meta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/help.vue").then(m => m.default || m)
  },
  {
    name: indexo6gvjD7hBVMeta?.name ?? "lottery",
    path: indexo6gvjD7hBVMeta?.path ?? "/lottery",
    meta: indexo6gvjD7hBVMeta || {},
    alias: indexo6gvjD7hBVMeta?.alias || [],
    redirect: indexo6gvjD7hBVMeta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/lottery/index.vue").then(m => m.default || m)
  },
  {
    name: ticketsMjyt5c9uv1Meta?.name ?? "lottery-tickets",
    path: ticketsMjyt5c9uv1Meta?.path ?? "/lottery/tickets",
    meta: ticketsMjyt5c9uv1Meta || {},
    alias: ticketsMjyt5c9uv1Meta?.alias || [],
    redirect: ticketsMjyt5c9uv1Meta?.redirect,
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/modules/03.core/pages/lottery/tickets.vue").then(m => m.default || m)
  }
]