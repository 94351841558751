
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "umami": {
    "id": "",
    "host": "",
    "version": 1,
    "domains": "",
    "debug": false,
    "autoTrack": true,
    "useDirective": false,
    "customEndpoint": "/",
    "ignoreLocalhost": false
  },
  "nuxt": {
    "buildId": "a7b717f3-614c-4114-b145-e981b82127dd"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/usr/src/node-app/app.config.ts"
import cfg1 from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/app.config.ts"
import cfg2 from "/usr/src/node-app/node_modules/.pnpm/nuxt-umami@2.6.2/node_modules/nuxt-umami/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, inlineConfig)
